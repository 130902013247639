.imageClass {
  width: 10rem;
  height: 10rem;
}

.wishClass {
  border-color: #73c98a;
  border-radius: 1rem;
  border-style: solid;
  max-width: 50rem;
  margin-bottom: 1rem;
}

.wishItem1,
.wishItem2,
.wishItem3,
.wishItem4,
.wishItem5 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.wishItem1 {
  grid-area: header;
  padding-bottom: 1rem;
}
.wishItem2 {
  grid-area: menu;
}
.wishItem3 {
  grid-area: main;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}
.wishItem4 {
  grid-area: right;
  padding: 1rem;
}
.wishItem5 {
  grid-area: footer;
}

.grid-container {
  display: grid;
  grid-template-areas:
    "header header header header header header"
    "menu main main main right right"
    "menu main main main footer footer";
  padding: 1rem;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 1.2rem;
}
