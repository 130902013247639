.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.mig {
  width: 80%;
  transform: rotate(5deg);
  position: relative;
  box-shadow: 10px 10px 5px #8f8f8f
}