.socialIconClass {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
}

@media screen and (max-width: 900px) {
    .socialIconClass {
        margin-left: 0.3125rem;
        margin-right: 0.3125rem;
    }
}

.socialsClass {
  display: flex; 
  justify-content: center; 
  align-content: center;
  margin-top:2rem; 
}